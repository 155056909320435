import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Login from './Login';
import App from "./App.js";
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import TalentStep1 from './components/pages/talento/TalentStep1.js';
import TalentStep2 from './components/pages/talento/TalentStep2.js';
import Done from './components/pages/talento/Done.js';
import Project_admin from './components/pages/Project_admin.js';



const router = createBrowserRouter([{
  path: "/",
  element:<Login/>
},
{
  path: "/dashboard",
  element:<App/>
},
{
  path: "/talent_step1",
  element:<TalentStep1/>
},
{
  path: "/talent_step2",
  element:<TalentStep2/>
},
{
  path: "/talent_step2/done",
  element:<Done/>
},
{
  path: "/project",
  element:<App page={"Project_admin"}/>
},
{
  path: "/Projects_relations",
  element:<App page={"Projects_relations"}/>
}
,
{
  path: "/Despachos",
  element:<App page={"Despachos"}/>
}
,
{
  path: "/Units_status",
  element:<App page={"Units_status"}/>
},
{
  path: "/Members_master",
  element:<App page={"Members_master"}/>
},
{
  path: "/Checkpoints",
  element:<App page={"Checkpoints"}/>
},


]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId='1091848897000-pu33qq783tvs4vho7sncg7aj1udocd2o.apps.googleusercontent.com'>
    <RouterProvider router={router}/>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
