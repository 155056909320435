import React, { useEffect, useRef, useState } from 'react';
import './TalentStep1.css';
import { create_card_checkpoint, create_checkpoint, get_checkpoints, get_current_cards, get_member_cards, peso, set_dedication } from '../../../utils/talent_services';
import { redirect, useNavigate } from 'react-router-dom';
import Members_master from './Members_master';
import packageJson from '../../../../package.json';

function TalentStep1 ({usr_id}) {

  const max_num_cards = 100;  // Numero maximo de tarjetas

  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  
  const [cards, setCards] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [countTarjetas, setcountTarjetas] = useState("");


/**
 * Trae todas las tarjetas del ultimo checkpoint creado, asociadas a este miembro, solo si aun no tienen costo asociado
 */
function validate_last_checkpoint  ()  {
  async function fetchCheckpoints() {
      const checkpointlist = await get_checkpoints(token);
      if (checkpointlist==="INVALID"){
        navigate("/",  { state: { parametro: 'talent_step1' } });
      }else{
        //Elijo el checkpoint mas reciente que es el elemento 0 porque vienen ordenados por fecha
      
        let checkPoint_id = checkpointlist[0].id;
        console.log ("Checkpoint actual:" + checkPoint_id);
        
        const card_to_evaluate = await get_member_cards(token, checkPoint_id );
        if (card_to_evaluate!=="INVALID"){

            setCards([]);
     
                
            const currentKr = card_to_evaluate.map((celement, index) => {
                   
                    return {cpCardid: celement.id, selected:false,  cardname: celement.title, shortUrl:celement.shortURL, index: index };
                  });

                  setcountTarjetas(currentKr.length);
                  setCards(currentKr);
       }
       //Ahora obtengo las tarjetas de este check point
     }
    }
    fetchCheckpoints();
}


useEffect(() => {
  validate_last_checkpoint();
}, [token]); // Solo se vuelve a ejecutar si 'token' cambia




const selectAll = (e)=>{

  const isChecked = e.target.checked;
  const updatedCards = cards.map((element) => {
    
      return { ...element, selected: isChecked }; // Actualizamos el peso
    
  });

  setCards(updatedCards); // Actualizamos el estado con la copia nueva
  if (!isChecked){
    setSelectedCards([]);
  }else{
    setSelectedCards(updatedCards);
  }
 
  
}



const handleCheckboxChange = (cardindex, e)=>{

  const isChecked = e.target.checked;


  console.dir (selectedCards.length);

  if (isChecked){
    if(selectedCards.length < max_num_cards){
      cards[cardindex].selected = true;
      setSelectedCards(prevCards => [...prevCards, cards[cardindex]]);
    }
  }else{
    cards[cardindex].selected = false;
    setSelectedCards((prevSelectedCards) => 
      prevSelectedCards.filter(element => element.index !== cardindex)
    );
  }

 
  
}


function set_dedications(selectedCards){
  
  
  
  if (selectedCards.length === 0){
    alert("No ha seleccionado ninguna tarjeta");
    return;
  }


  
  let peso_general = 20 / (cards.length - selectedCards.length);


  console.log("peso general:" + peso_general);
  const updatedCards = cards.map((element,index) => {
    const newPeso = parseFloat(peso_general);
   
    // Evaluamos la condición antes de actualizar
    if (element.selected ) {
     // return{...element};
      const selectedIndex = selectedCards.findIndex(
          (selectedElement) => selectedElement.cpCardid === element.cpCardid
      );
      console.log ("/////Selected:" + selectedIndex + " selected L:" + selectedCards.length + " total:" + cards.length );
      return { ...element, peso: parseFloat(peso( selectedCards.length, selectedIndex+1, (cards.length !== selectedCards.length?80:100))) }; // Actualizamos el peso
    }else{
      return { ...element, peso: newPeso }; // Actualizamos el peso
    }
  });


  setCards(updatedCards); // Actualizamos el estado con la copia nueva


  let totalselected =0;
  let total_not_selected =0;
  
  let total =0;
  updatedCards.forEach((element, index) => {
      console.log( element.cardname + " = " + element.peso);
      if (element.selected){
        totalselected += parseFloat(element.peso);
      }else{
        total_not_selected += parseFloat(element.peso);
      }
      total += parseFloat(element.peso);
 
   });

   
   console.log("Total Selected: " + totalselected);
   
   console.log("Total Not Selected: " + total_not_selected);
   console.log("Total Dedicacion: " + total);


  async function set_dedication_values(updatedCards) {
    const checkpointlist = await set_dedication(token,updatedCards );
    if (checkpointlist==="INVALID"){
      navigate("/");
    }else{
      navigate("/talent_step2");
   }
  }
  set_dedication_values(updatedCards);
 
}

const handlebt_create_checkpoint = () => {
  set_dedications(selectedCards);
}


///////////


  return (
    <div className="containerChk">
     
      <div className="right-panel">
      v{packageJson.version}
      <div className='button-container'>
             <button className="add-cards-btn" onClick={handlebt_create_checkpoint} >
            Siguiente
          </button>
          
        </div>
        <h4> Selecciona en orden de mayor a menor las tarjetas a las que más dedicaste tiempo de las {countTarjetas} en las que participaste</h4>
        <table className="selectedTable">
          <thead>
     
          </thead>
          <tbody>
            {selectedCards.map((card, index) => {
                    return (
                        <tr key={index}>
                        <td className="numbersRow" style={{ width: '10%' }}>
                         {index + 1}
                      </td>
                        <td style={{ width: '90%' }}><a href={card.shortUrl} target="_blank">{card.cardname}</a></td>
                        
                        </tr>
                      )
                }
              )}
          </tbody>
        </table>
        <div className='cards-list'>
        <table className="krtable">
          <thead>
            <tr>
              <th> <input
                          type="checkbox"
                          onChange={(e) => selectAll(e)} 
                        /></th>
              <th>Tarjetas</th>
            </tr>
          </thead>
          <tbody>
            {cards.map((card, index) => {
                    return (
                        <tr
                          key={index}
                        >
                        <td style={{ width: '5%' }}>
                        <input
                          type="checkbox"
                          checked={card.selected}
                          onChange={(e) => handleCheckboxChange(index, e)} 
                        />
                      </td>
                        <td style={{ width: '95%' }}><a href={card.shortUrl} target="_blank">{card.cardname}</a></td>
                        
                        </tr>
                      )
                }
              )}
          </tbody>
        </table>
        </div>
        </div>
      
      
    </div>
  );
};

export default TalentStep1;
