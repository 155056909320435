import React, { useEffect, useRef, useState } from 'react';
import './Project_admin.css';
import { add_serial, delete_projects_relation, get_project_serials, update_project} from '../../utils/services';
import {  useNavigate, useSearchParams } from 'react-router-dom';

function Project_admin  () {

  const serialInputRef = useRef(null)
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [num_seriales, set_num_serials] = useState(0);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id"); // Obtiene el valor de 'id'
  const [nombre_proy, setnombre_proy] = useState('');
  const [country, setcountry] = useState('');
  const [link_token, setlink_token] = useState('');
  const [ok_serials, set_ok_serials] = useState('');
  const [failed_serials, set_failed_serials] = useState('');
  const [num_seriales_procesados, set_procesados] = useState(0);

  let seriales;

  //LIST OF SERIAL MEMBERS:
  const [SerialList, setSerialList] = useState([]);



  const refresh_projects_list = (id) => {
    async function fetchCheckpoints() {
        const membersList = await get_project_serials(token, id);
        if (membersList==="INVALID"){
          navigate("/");
        }else{
          const members = membersList['serials'].map((element, index) => {
            return {id:element.id, serial: element.tomi_serial, sub_cat: element.sub_cat, index: index };
          });
          setSerialList(members);


          
        setnombre_proy (membersList[0].project_name);
        setcountry (membersList[0].country);
        setlink_token (membersList[0].link_token);


                  
       }
      }
      fetchCheckpoints();
  }

  const countSerials =(e) =>{
    let seriales;
    seriales = e.split('\n');
    set_num_serials (seriales.length);

}

  
  const btDelete = (id_relation) => {
   

    async function fetchDeleteRelation(id_relation) {
      const membersList = await delete_projects_relation(token, id_relation);
      if (membersList==="INVALID"){
        navigate("/");
      }else{
          refresh_projects_list(id);
     }
    }
    fetchDeleteRelation(id_relation);
};

const btUpdateProj = () => {
   

  async function fetchDeleteRelation() {
    const membersList = await update_project(token,id, nombre_proy, link_token, country);
    if (membersList==="INVALID"){
      navigate("/");
    }else{
        refresh_projects_list(id);
   }
  }
  fetchDeleteRelation();
};

  
  useEffect(() => {
    if (id>0){
      refresh_projects_list(id);
    }
  }, [token, id]); // Solo se vuelve a ejecutar si 'token' cambia


  
  const insertSerialClick = () => {

    async function fetchChangeStatus() {
      
      if ( serialInputRef.current.value === ""){
        alert("Faltan datos");
        return;
      }

      seriales = serialInputRef.current.value.split('\n');
      set_ok_serials("");
      set_failed_serials("");

      

      let count = 0;
      for (const serial of seriales) {
        count +=1;
        set_procesados(count);
        if (serial !== "") {


            let serials_sub_cat = serial.split(",");

            const deviceStatus = await add_serial(token, serials_sub_cat[0], (serials_sub_cat[1] ?? ""), id);

            if (deviceStatus !== "OK") {
                set_failed_serials(prevString => prevString + "\n " + serials_sub_cat[0]);
            } else {
              // 9 Type Logistics annotation in issue_type
              if (deviceStatus === "INVALID") {
                set_failed_serials(prevString => prevString + "\n " + serials_sub_cat[0] );
              } else {
                set_ok_serials(prevString => prevString + "\n " + serials_sub_cat[0]);
              }
            }
        }
      }

      refresh_projects_list(id); 
      }
  
      fetchChangeStatus();

  }





  return (
    <div className="container2">

<div className="containerChk">
<div className="left-panel">
  <div className="field-container">
    <label htmlFor="id">ID:</label>
    <span>{id}</span>
  </div>
  
  <div className="field-container">
    <label htmlFor="nombre">Nombre:</label>
    <input type="text" id="nombre" value={nombre_proy} onChange={(e) => setnombre_proy(e.target.value)} />
  </div>
  
  <div className="field-container">
    <label htmlFor="link-token">Link Token:</label>
    <input type="text" id="link-token" value={link_token} onChange={(e) => setlink_token(e.target.value)}/>
  </div>
  
  <div className="field-container">
    <label htmlFor="pais">País:</label>
    <input type="text" id="pais"  value={country} onChange={(e) => setcountry(e.target.value)} />
  </div>

    <div className="field-container">
    <label htmlFor="pais">País:</label>
    <input type="text" id="pais"  value={country} onChange={(e) => setcountry(e.target.value)} />
  </div>

  <div className="button-container">
    <button className="add-cards-btn" onClick={btUpdateProj}>Actualizar Proyecto</button>
  </div>
 

  
  <label >serial o serial,sub_cat por linea </label>
  <textarea
    id="serial"
    rows="8"
    cols="50"
    onChange={(e) => countSerials(e.target.value)}
    ref={serialInputRef}
  />
<label htmlFor="serial">Seriales por agregarse: {num_seriales}</label>
    <div className="field-container">
       <label> Procesados #: {num_seriales_procesados} </label>
     </div>
     <div className="field-container">
        <label> Seriales Registados:  {ok_serials} </label>
      </div>

      <div className="field-container">
        <label> Seriales fallidos: {failed_serials} </label>
        </div>

  <div className="button-container">
    <button className="add-cards-btn" onClick={insertSerialClick}>Agregar seriales al proyecto</button>
  </div>
</div>
      <div className="right-panel2">
                  
                  <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Serial</th>
                    <th>Sub_cat</th>
                    <th>Eliminar</th>
                  </tr>
                </thead>
                <tbody>
                  {SerialList.map((serial, index) => (
                    <tr key={serial.id}>
                      <td>{serial.serial}</td>
                      <td>{serial.sub_cat}</td>
                      <td>
                        <button
                          onClick={() => { btDelete(serial.id); }}
                          style={{
                            background: 'none',
                            border: 'none',
                            color: 'red',
                            cursor: 'pointer',
                            fontSize: '1.5rem'
                          }}
                        >
                          ❌
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>


      </div>

    </div>


      
    </div>
  );
};

export default Project_admin;
