import React, { useEffect, useState } from 'react';
import './TOMi8versions.css';
import { getPackages, getPackageVersion } from '../../utils/services';
import {  useNavigate } from 'react-router-dom';

function TOMi8versions  () {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  let file;
  const [dropdownOptions, setDropdownOptions] = useState([]); // Estado para las opciones del dropdown
  const [versions, setVersions] = useState([]);
  const [newVersion, setNewVersion] = useState({
    versionCode: '',
    versionName: '',
    fechaPublish: '',
    comments: ''
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVersion((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
   file = e.target.files[0];
    console.log('Selected file:', file);
  
  };

  const addVersion = () => {
    
    async function fetchAddVersion() {
      const packageslist = await addVersion(token, file, );
      if (packageslist==="INVALID"){
        navigate("/");
      }else{

        const packageNames = packageslist.map(element => element.package_name);

        
     }
    }

    fetchAddVersion();

  };

  useEffect(() => {

    async function fetchPackages() {
      const packageslist = await getPackages(token);
      if (packageslist==="INVALID"){
        navigate("/");
      }else{

        console.log("Lista: " + packageslist[0].package_name);
        
        const packageNames = packageslist.map(element => element.package_name);
        packageNames.unshift("Select one...");
        setDropdownOptions(packageNames);
        
     }
    }

    fetchPackages();
  }, [token]); // Solo se vuelve a ejecutar si 'token' cambia

  const handleDropdownChange = (e) => {

    async function fetchVersions(packageName) {
      const packageVersionlist = await getPackageVersion(token, packageName);
      if (packageVersionlist==="INVALID"){
        navigate("/");
      }else{
        try{
          const newVersions = packageVersionlist.map(item => ({
            versionCode: item.version,
            versionName: item.version_name,
            fechaPublish: item.release_date,
            comments: item.description,
          }));
          
          setVersions(newVersions);
        }catch (err) {
          
        }

        
     }
    }

    fetchVersions(e.target.value);
  };

  return (
    <div className="container">
      <div className="dropdown">
        <select className="dropdown-select" onChange={handleDropdownChange}>
           {dropdownOptions.map((option, index) => (
            <option key={index} value={option}  >
              {option}
            </option>
          ))}
        </select>
      </div>

      <div className="table-container">
        <table className="version-table">
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Date</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {versions.map((version, index) => (
              <tr key={index}>
                <td style={{ width: '5%' }}>{version.versionCode}</td>
                <td style={{ width: '5%' }}>{version.versionName}</td>
                <td style={{ width: '10%' }}>{version.fechaPublish}</td>
                <td style={{ width: '80%', whiteSpace: "pre-line" }}>{version.comments}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="new-version">
        <h3>New Version</h3>
        <div className="form-group">
          <label>Comments:</label>
          <textarea
            name="comments"
            value={newVersion.comments}
            onChange={handleInputChange}
            className="textarea"
          />
        </div>
        <div className="form-group">
          <label>Version Code:</label>
          <input
            type="text"
            name="versionCode"
            value={newVersion.versionCode}
            onChange={handleInputChange}
            className="input"
          />
        </div>
        <div className="form-group">
          <label>Version Name:</label>
          <input
            type="text"
            name="versionName"
            value={newVersion.versionName}
            onChange={handleInputChange}
            className="input"
          />
        </div>
        <div className="form-group">
          <label>Package:</label>
          <input type="file" onChange={handleFileChange} className="file-input" />
          <button onClick={addVersion} className="add-button">Agregar</button>
        </div>
      </div>
    </div>
  );
};

export default TOMi8versions;
