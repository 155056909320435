import { useState } from "react";
import { useNavigate } from "react-router-dom";

function T8_projects_mnu({setactivity}) {
  const [isProductionOpen, setProductionOpen] = useState(true); // Predeterminadamente abierto
  const navigate = useNavigate();
  return (
    <li className="menu-item">
          <div onClick={() => setProductionOpen(!isProductionOpen)} className="menu-title">
            <span role="img" aria-label="key">🏨</span> Project Management
            <span className={`arrow ${isProductionOpen ? 'open' : ''}`}>▾</span>
          </div>
          {isProductionOpen && (
            <ul className="submenu">
              <li onClick={() => navigate('/Projects_relations')}>Proyectos</li>
            </ul>
          )}
        </li>
  );
}

export default T8_projects_mnu;
