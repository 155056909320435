import React, { useEffect, useRef, useState } from 'react';
import './Projects_relations.css';
import { add_project, get_projects, getPackages, getPackageVersion } from '../../utils/services';
import {  useNavigate } from 'react-router-dom';

function Projects_relations  ({user_id, permission}) {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const modelInputRef = useRef(null);

  //LIST OF SERIAL MEMBERS:
  const [projects, setprojects] = useState([]);
  const [newName, setNewName] = useState('');
  const [editIndex, setEditIndex] = useState(null);

  const handle_email_alias = (index, costo) => {
    setEditIndex(index);
    setNewName(costo);
  };

  const handle_update_email_alias = (memberId) => {
    handleUpdateEmail(memberId);
  };


  const handleUpdateEmail = (memberId) => {
    /*
    async function fetchUpdatemember() {
      console.log(memberId + " " + newEmail);
      const memberUpdate = await update_member(token, memberId, newEmail);
      if (memberUpdate==="INVALID"){
        navigate("/");
      }else{
        setEditIndex(null);
        refresh_members_list();
     }
    }
    fetchUpdatemember(memberId);
    */
  };


  const refresh_projects_list = () => {
    async function fetchCheckpoints() {
        const membersList = await get_projects(token);
        if (membersList==="INVALID"){
          navigate("/");
        }else{

          const members = membersList.map((element, index) => {
            return {id:element.project_id, name: element.project_name, country: element.country, link_token: element.link_token, count_serials: element.t8s, creation_date: element.creation_date, owner_mail: element.owner_mail, index: index };
          });

          setprojects(members);
                  
       }
      }
      fetchCheckpoints();
  }

  const selectCheckpoint = (index, id) => {
      navigate("/project?id=" + id);
  };

  
  useEffect(() => {
    refresh_projects_list();
  }, [token]); // Solo se vuelve a ejecutar si 'token' cambia


  
  const handleAddBt = () => {

 
    async function fetchAddModel(newProject) {
      
       
      const deviceMessages = await add_project(token, newProject);
      if (deviceMessages==="INVALID"){
        navigate("/");
      }else{
         refresh_projects_list();

     }
    }

    fetchAddModel(modelInputRef.current.value);
  };


  return (
    
     <div className="container">
    
     
      <div className="table-container2">
      <table className="version-table2">
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Nombre</th>
              <th style={{ width: '15%' }}>Pais</th>
              <th style={{ width: '25%' }}>link_token</th>
              <th style={{ width: '5%' }}># T8</th>
              <th style={{ width: '15%' }}>Creado</th>
              <th style={{ width: '15%' }}>Por</th>

            </tr>
          </thead>
          <tbody>
            {projects.map((project, index) => {

                    return (
                        <tr
                          key={index}
                          onClick={() => selectCheckpoint(index, project.id)}
                        >
                     
                        <td>
                      
                          {project.name}
                      </td>
                    
                        <td>{project.country}</td>
                        <td>{project.link_token}</td>
                        <td>{project.count_serials}</td>
                        <td>{project.creation_date}</td>
                        <td>{project.owner_mail}</td>
                    
                        </tr>
                      )
          
              })}
          </tbody>
        </table>
    </div>

    {permission === "1" ? 
              
              <div className='newProject'>

              <input  type="text" id="new_project" ref={modelInputRef} />
              <button onClick={handleAddBt}>Agregar</button>
              </div>
            : null}
    </div>
  );
};

export default Projects_relations;
